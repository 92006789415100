import React from "react"
import {
  Container,
  Main,
  Cutin,
  Content,
  Contact,
  Iframely,
  Seo,
  FeatureImage,
  ContentCard,
  Button,
} from "../components"
import { FixedPageH1, FixedPageH2, FixedPageH4, P } from "../elements"
import { graphql, Link } from "gatsby"

const altarPage = ({ data, location }) => {
  return (
    <Container>
      <Seo
        pagetitle="愛知県（弥富）のマルスエ佛壇"
        pagedesc="愛知県（弥富）のマルスエ佛壇のページです。お仏壇、名古屋仏壇、家具調仏壇の情報をブログ形式でお届けします。"
        pagepath={location.pathname}
        pageimg={data.kumitate.childImageSharp.original.src}
        pageimgw={data.kumitate.childImageSharp.original.width}
        pageimgh={data.kumitate.childImageSharp.original.height}
      />
      <Iframely />
      <FeatureImage fixed={data.kumitate.childImageSharp.fixed} />
      <Cutin>
        <Main>
          <FixedPageH1>
            <h1>
              愛知県（弥富）のマルスエ佛壇
              <span>各宗派仏壇仏具製造販売</span>
            </h1>
          </FixedPageH1>
          <Content>
            <FixedPageH2>マルスエ佛壇から皆様へ</FixedPageH2>
            <P>マルスエ佛壇は創業大正１４年。</P>
            <P>
              弥富市の小さな仏壇店として、地域の方々にご奉仕して参りました。
            </P>
            <P>
              大きな仏壇店には店舗も在庫数も敵いませんが、マルスエ佛壇の強みは「製造力」です。
            </P>
            <P>
              職人として、地域の佛壇店として、数々のお仏壇を製造・修理・販売しております。
            </P>
            <P>
              当ページでは、創業より培ってきた「お仏壇のお役立ち情報」を発信しています。
            </P>
            <P>
              ご興味のある記事を読んで頂いた後は、疑問点やご相談など、お気軽にお問い合わせくださいませ。
            </P>
          </Content>
          <Content>
            <ContentCard
              fixed={data.sandaimeFixed.childImageSharp.fixed}
              title="マルスエ佛壇について"
              cover="About"
            >
              <div>
                <Button href="/profile/">どんなお店？ページへ</Button>
              </div>
            </ContentCard>
          </Content>
          <Content>
            <div id="service"></div>
            <FixedPageH2>サービス一覧</FixedPageH2>
            <ContentCard
              fixed={data.after001.childImageSharp.fixed}
              title="新しく造る"
              cover="Order"
            >
              <div>
                <Button href="/marusue-b-commitment/">
                  新品お仏壇ページへ
                </Button>
              </div>
            </ContentCard>
            <ContentCard
              fixed={data.osentakuSample002.childImageSharp.fixed}
              title="お仏壇の修復"
              cover="Repair"
            >
              <div>
                <Button href="/marusue-b-osentaku/">
                  お仏壇の修復ページへ
                </Button>
              </div>
            </ContentCard>
            <ContentCard
              fluid={data.service001.childImageSharp.fluid}
              title="お仏壇の移動・処分・お預かり"
              cover="Service"
            >
               <ul>
                    <li>
                      <Link to="/obutsudan-idou-shobun/">
                        お仏壇の移動・処分・お預かり
                      </Link>
                    </li>
                    <li>
                      <Link to="/gold-recycle/">
                        New「金リサイクル処分」
                      </Link>
                    </li>
                  </ul>
              <div>
                <Button href="/obutsudan-service/">お仏壇サービスへ</Button>
              </div>
            </ContentCard>
          </Content>
          <Content>
            <div id="yondaime-blog"></div>
            <ContentCard
              fluid={data.kanrisha.childImageSharp.fluid}
              title="四代目(仮)お仏壇情報ブログ"
              cover="Blog"
            >
              <div id="001"></div>
              <FixedPageH4>お仏壇とは？</FixedPageH4>
              <ul>
                <li>
                  <Link to="/obutsudan-nesessity/">お仏壇の役割</Link>
                </li>
              </ul>
              <FixedPageH4>お仏壇を選ぶ</FixedPageH4>
              <ul>
                <li>
                  <Link to="/obutsudan-how-to-select/">
                    お仏壇の選び方【まとめ記事】
                  </Link>
                </li>
                <li>
                  <Link to="/kinbutsudan/">金仏壇とは？</Link>
                </li>
                <li>
                  <Link to="/nagoyabutsudan/">名古屋仏壇とは？</Link>
                </li>
                <li>
                  <Link to="/kagucho-butsudan/">家具調仏壇とは？</Link>
                </li>
                <li>
                  <Link to="/built-in-obutsudan/">造り付け仏壇とは？</Link>
                </li>
                <li>
                  <Link to="/obutsudan-gohonzon/">お仏壇のご本尊</Link>
                </li>
                <div id="002"></div>
                <li >
                  <Link to="/obutsudan-haichi/">お仏壇の配置方法</Link>
                </li>
              </ul>
              <FixedPageH4>お仏壇を直す</FixedPageH4>
              <ul>
                <li>
                  <Link to="/obutsudan-repair/">
                    お仏壇の直し方【まとめ記事】
                  </Link>
                </li>
                <li>
                  <Link to="/obutsudan-osentaku/">お仏壇のお洗濯について</Link>
                </li>
                <li>
                  <Link to="/obutsudan-cleaning/">
                    お仏壇のクリーニングについて
                  </Link>
                </li>
                <div id="003"></div>
                <li>
                  <Link to="/obutsudan-remake/">お仏壇のリメイクについて</Link>
                </li>
              </ul>
              <FixedPageH4>お仏壇・仏具を維持する</FixedPageH4>
              <ul>
                <li>
                  <Link to="/obutsudan-kazarikata/">
                    お仏壇の飾り方【まとめ記事】
                  </Link>
                </li>
                <li>
                  <Link to="/obutsudan-flower/">
                    お花のお供え（仏花）について
                  </Link>
                </li>
                <li>
                  <Link to="/obutsudan-buppan/">
                    ご飯のお供え（仏飯）について
                  </Link>
                </li>
                <li>
                  <Link to="/obutsudan-maintenance/">
                    お仏壇のお手入れ【まとめ記事】
                  </Link>
                </li>
                <div id="004"></div>
                <li>
                  <Link to="/obutsudan-electric-light/">
                    お仏壇の電気について・トラブル対策
                  </Link>
                </li>
              </ul>
              <FixedPageH4>移動・お預かり・処分について</FixedPageH4>
              <ul>
                <li>
                  <Link to="/obutsudan-idou-shobun/">
                    お仏壇の移動・処分・預かり【まとめ記事】
                  </Link>
                </li>
                <li>
                  <Link to="/obutsudan-move/">お仏壇の移動方法</Link>
                </li>
                <li>
                  <Link to="/obutsudan-shobun/">お仏壇の処分方法</Link>
                </li>
              </ul>
            </ContentCard>
          </Content>
          <Content>
            <FixedPageH2>お問い合わせ</FixedPageH2>
            <Contact />
            <div className="iframely-embed">
              <div
                className="iframely-responsive"
                style={{ height: 140, paddingBottom: 0 }}
              >
                <a
                  href="https://www.google.com/maps/search/?api=1&query=%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87"
                  data-iframely-url="//cdn.iframe.ly/api/iframe?url=https%3A%2F%2Fwww.google.com%2Fmaps%2Fsearch%2F%3Fapi%3D1%26query%3D%E3%83%9E%E3%83%AB%E3%82%B9%E3%82%A8%E4%BD%9B%E5%A3%87&key=bbb152fda61816746702349ee0e12e2b"
                  aria-label="GoogleMaps"
                >
                  {null}
                </a>
              </div>
            </div>
          </Content>
        </Main>
      </Cutin>
    </Container>
  )
}

export const query = graphql`
  query {
    kumitate: file(relativePath: { eq: "kumitate.jpg" }) {
      childImageSharp {
        original {
          height
          src
          width
        }
      }
    }
    kumitate: file(relativePath: { eq: "kumitate.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    osentakuSample002: file(relativePath: { eq: "osentaku-sample002.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    after001: file(relativePath: { eq: "after001.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    service001: file(relativePath: { eq: "service001.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kobutsuzenshu: file(relativePath: { eq: "kobutsuzenshu.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    sandaimeFixed: file(relativePath: { eq: "sandaimeFixed.jpg" }) {
      childImageSharp {
        fixed(width: 1080, quality: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    kanrisha: file(relativePath: { eq: "kanrishaSagyou.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 30) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default altarPage
